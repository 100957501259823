/* ----- Color Variable Spec -----
    p -> Primary
    s -> Secondary
    d -> Dark
    l -> Light
    t -> Status
*/
.UFNXhqTDbEYGauTtb1vvE {
  max-width: 500px;
  width: 100%; }

._2t_i-wSjEmXJ53_bh-wsQe {
  font-size: 16px;
  font-weight: 500;
  display: flex; }
  ._2t_i-wSjEmXJ53_bh-wsQe ._2aaREISdibZLoovwEygfTK {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    background-color: #1d408b;
    color: #ffffff;
    border-radius: 4px;
    padding: 0px 6px;
    margin-right: 0.5rem; }

.gmVNfop6LvkHT9Sk6n_vp {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
  padding-left: 0.75rem;
  height: 100px; }
  .gmVNfop6LvkHT9Sk6n_vp li {
    flex: 50%;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 500; }
    .gmVNfop6LvkHT9Sk6n_vp li input[type='checkbox'] {
      margin-right: 0.5rem; }

._3Bx1HnbjvZCNqTpMOsT1O8 th:first-child,
._3Bx1HnbjvZCNqTpMOsT1O8 td:first-child {
  width: 80px;
  text-align: left;
  padding-right: 20px; }

._3Bx1HnbjvZCNqTpMOsT1O8 th:last-child,
._3Bx1HnbjvZCNqTpMOsT1O8 td:last-child {
  width: 40px;
  padding-right: 0; }

._3Bx1HnbjvZCNqTpMOsT1O8 tr td {
  padding-top: 10px;
  padding-bottom: 13px;
  vertical-align: bottom; }
  ._3Bx1HnbjvZCNqTpMOsT1O8 tr td:last-child {
    text-align: center;
    vertical-align: bottom;
    height: 50px;
    padding-bottom: 18px; }

._2oo-7Dvnoy1lqJjHi0Fdp {
  padding: 5px;
  background-color: transparent;
  border: transparent; }
  ._2oo-7Dvnoy1lqJjHi0Fdp:hover:not(:disabled), ._2oo-7Dvnoy1lqJjHi0Fdp:focus:not(:disabled) {
    background-color: #eef5ff; }
  ._2oo-7Dvnoy1lqJjHi0Fdp:disabled ._1EjZNlF8TycuLBTEe_bnZ1 {
    background-color: #979797; }
  ._2oo-7Dvnoy1lqJjHi0Fdp ._1EjZNlF8TycuLBTEe_bnZ1 {
    background-color: #3779f7;
    color: #ffffff;
    padding: 5px;
    border-radius: 400px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px; }
