/* ----- Color Variable Spec -----
    p -> Primary
    s -> Secondary
    d -> Dark
    l -> Light
    t -> Status
*/
._1KPOhJa6E9qT1rj3bCPoyd {
  max-width: 1200px;
  width: 100%; }

._35U83Biu3VXmyDVi-61VH6 {
  display: flex;
  width: 100%;
  min-height: 500px;
  max-height: 50vh; }
  ._35U83Biu3VXmyDVi-61VH6 ._1dvOFbnigm9hdCxk5Ui9pT {
    flex: 0.2;
    background-color: #fcfcfc; }
    ._35U83Biu3VXmyDVi-61VH6 ._1dvOFbnigm9hdCxk5Ui9pT ._1WeYeFm1TlANF4aq66z-Bl {
      max-height: calc(100% - 30px);
      height: calc(100% - 30px);
      overflow-y: auto;
      position: relative; }
      ._35U83Biu3VXmyDVi-61VH6 ._1dvOFbnigm9hdCxk5Ui9pT ._1WeYeFm1TlANF4aq66z-Bl ul {
        list-style-type: none;
        margin: 0; }
        ._35U83Biu3VXmyDVi-61VH6 ._1dvOFbnigm9hdCxk5Ui9pT ._1WeYeFm1TlANF4aq66z-Bl ul li.MZVpveUZ75ZPnXm4Tf7h5 {
          margin: 1.5rem 1rem 1rem;
          padding: 0;
          color: #1d408b;
          font-weight: bold; }
        ._35U83Biu3VXmyDVi-61VH6 ._1dvOFbnigm9hdCxk5Ui9pT ._1WeYeFm1TlANF4aq66z-Bl ul a,
        ._35U83Biu3VXmyDVi-61VH6 ._1dvOFbnigm9hdCxk5Ui9pT ._1WeYeFm1TlANF4aq66z-Bl ul div {
          display: inline-block;
          width: 100%;
          padding: 0.75rem 1rem;
          cursor: pointer;
          position: relative;
          text-decoration: none;
          font-weight: bold; }
          ._35U83Biu3VXmyDVi-61VH6 ._1dvOFbnigm9hdCxk5Ui9pT ._1WeYeFm1TlANF4aq66z-Bl ul a._15AmGeLdkls4LJ4U2Y52VK,
          ._35U83Biu3VXmyDVi-61VH6 ._1dvOFbnigm9hdCxk5Ui9pT ._1WeYeFm1TlANF4aq66z-Bl ul div._15AmGeLdkls4LJ4U2Y52VK {
            background-color: #ededed; }
          ._35U83Biu3VXmyDVi-61VH6 ._1dvOFbnigm9hdCxk5Ui9pT ._1WeYeFm1TlANF4aq66z-Bl ul a._1coVL-HxWwYZJDYlx3_QaU,
          ._35U83Biu3VXmyDVi-61VH6 ._1dvOFbnigm9hdCxk5Ui9pT ._1WeYeFm1TlANF4aq66z-Bl ul div._1coVL-HxWwYZJDYlx3_QaU {
            padding-left: 2rem; }
        ._35U83Biu3VXmyDVi-61VH6 ._1dvOFbnigm9hdCxk5Ui9pT ._1WeYeFm1TlANF4aq66z-Bl ul div {
          display: flex;
          justify-content: space-between;
          align-items: center; }
  ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 {
    flex: 0.6;
    background-color: #ffffff;
    padding: 1.5rem 1.25rem; }
    ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._1VIPirAka4WqbGOlCd6FzY {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding-bottom: 1rem; }
      ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._1VIPirAka4WqbGOlCd6FzY .Dtv-tOD2VfNIt7kiPS8TI {
        display: flex;
        align-items: center; }
        ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._1VIPirAka4WqbGOlCd6FzY .Dtv-tOD2VfNIt7kiPS8TI h1 {
          font-size: 1.125rem;
          font-weight: bold;
          color: #282828;
          margin: 0;
          margin-right: 8px; }
      ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._1VIPirAka4WqbGOlCd6FzY p {
        padding: 8px;
        font-size: 14px; }
    ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 {
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      width: 100%; }
      ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._3UfZ7oIroySvA-uEd9R1A9 {
        justify-content: center;
        width: 100%;
        height: 90%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        border: dashed 2px #88b1ff;
        background-color: #fcfcfc;
        border-radius: 0.5rem; }
        ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._3UfZ7oIroySvA-uEd9R1A9 h3 {
          font-size: 1rem;
          color: #000000; }
        ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._3UfZ7oIroySvA-uEd9R1A9 div {
          display: inline-block;
          background-color: #deebff;
          padding: 1rem;
          margin: 1rem 0rem;
          border-radius: 50%; }
          ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._3UfZ7oIroySvA-uEd9R1A9 div div {
            color: #3779f7;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            text-decoration: none; }
            ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._3UfZ7oIroySvA-uEd9R1A9 div div svg {
              width: 24px;
              height: 24px; }
      ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._14UkMjNJ0qvHa4Jm1PANoG {
        justify-content: center;
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center; }
        ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._14UkMjNJ0qvHa4Jm1PANoG figure {
          width: 500px;
          height: 300px;
          margin: 0;
          border: 1px solid #e5e5e5; }
          ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._14UkMjNJ0qvHa4Jm1PANoG figure img {
            width: 100%;
            height: 100%;
            object-fit: cover; }
      ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._11ViXfd7dwoBtUhp3cL6zy {
        justify-content: center;
        width: 100%;
        height: 90%;
        display: flex;
        flex-direction: column;
        align-items: center; }
        ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._11ViXfd7dwoBtUhp3cL6zy a._2t6ukRowECugggSg_9X2L0 {
          margin: 0;
          border: 1px solid #e5e5e5;
          width: 400px; }
          ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._11ViXfd7dwoBtUhp3cL6zy a._2t6ukRowECugggSg_9X2L0 figure {
            width: 400px;
            height: 300px;
            margin: 0;
            border-bottom: 1px solid #e5e5e5; }
            ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._11ViXfd7dwoBtUhp3cL6zy a._2t6ukRowECugggSg_9X2L0 figure img {
              width: 100%;
              height: 100%;
              object-fit: contain; }
          ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._11ViXfd7dwoBtUhp3cL6zy a._2t6ukRowECugggSg_9X2L0 figcaption {
            width: 100%;
            padding: 12px; }
            ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._11ViXfd7dwoBtUhp3cL6zy a._2t6ukRowECugggSg_9X2L0 figcaption p.MZVpveUZ75ZPnXm4Tf7h5 {
              font-family: ibm-plex-thai;
              font-size: 16px;
              line-height: 1.75;
              font-weight: 600;
              color: #282828;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden; }
            ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 ._11ViXfd7dwoBtUhp3cL6zy a._2t6ukRowECugggSg_9X2L0 figcaption p._18M0ncdJTadcFprQ3yTOOx {
              font-family: Sarabun;
              font-size: 14px;
              font-weight: 300;
              line-height: 1.79;
              color: #000000;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden; }
      ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 figure {
        width: 145px;
        height: 100px;
        margin: 0rem 1rem 3rem 0rem;
        position: relative;
        cursor: pointer; }
        ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 figure._15AmGeLdkls4LJ4U2Y52VK {
          outline: 3px solid #3779f7; }
        ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 figure img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
        ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 figure span._3c6yvF5HLnWCLlSIFYmdnd {
          border-radius: 0.2rem;
          padding: 0px 5px;
          background-color: #ffffff;
          position: absolute;
          left: 0.2rem;
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
          top: 0.2rem;
          font-size: 0.875rem;
          color: #282828; }
        ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 figure span.KbZoCRPdk2lFQvDWTE6PC {
          opacity: 0.7;
          background-color: #000000;
          width: 100%;
          bottom: 0;
          left: 0;
          text-align: center;
          font-size: 0.75rem;
          position: absolute;
          color: #fff; }
        ._35U83Biu3VXmyDVi-61VH6 ._28HefRtfFhMCQtaPVf4Sa3 ._2t6ukRowECugggSg_9X2L0 figure figcaption {
          margin-top: 0.25rem;
          text-align: center; }
  ._35U83Biu3VXmyDVi-61VH6 ._2iQyv3gWTHMau8P4L4FJpD {
    flex: 0.25;
    background-color: #ffffff;
    border-left: solid 1px #e5e5e5;
    overflow-y: auto; }
    ._35U83Biu3VXmyDVi-61VH6 ._2iQyv3gWTHMau8P4L4FJpD figure {
      width: 100%;
      height: 180px; }
      ._35U83Biu3VXmyDVi-61VH6 ._2iQyv3gWTHMau8P4L4FJpD figure img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    ._35U83Biu3VXmyDVi-61VH6 ._2iQyv3gWTHMau8P4L4FJpD ._34Nn9qhUMrw1A3UMj9_n6I {
      padding: 0 0.5rem; }

._3ck1htiFFWfeBTMcgfLVJM._15AmGeLdkls4LJ4U2Y52VK {
  background-color: #ededed; }

._3mhgTSqztlZ2-jenv_6ARS {
  display: none; }
  ._3mhgTSqztlZ2-jenv_6ARS._15AmGeLdkls4LJ4U2Y52VK {
    display: block; }
  ._3mhgTSqztlZ2-jenv_6ARS li a {
    padding-left: 80px; }

._2dYFW3gpenFYqgGbA8d4H7 {
  position: absolute;
  background-color: #fff !important;
  border-radius: 4px !important;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff !important;
  width: 200px;
  top: 100%;
  left: -50%; }
  ._2dYFW3gpenFYqgGbA8d4H7 button {
    width: 100%;
    background-color: #fff !important;
    margin-bottom: 10px; }
