/* ----- Color Variable Spec -----
    p -> Primary
    s -> Secondary
    d -> Dark
    l -> Light
    t -> Status
*/
@media only screen and (max-width: 414px) {
  ._2i1jZpksXLzVKDgb6Y_uEt {
    display: none !important; } }

.-MXn6r6EpJLP3dW3OuhK_ {
  display: none !important; }
  @media only screen and (max-width: 414px) {
    .-MXn6r6EpJLP3dW3OuhK_ {
      display: flex !important; } }

._2i2fp-0T_hfT-VZ4ZErdkL {
  max-width: 600px;
  width: 100%; }

._311oQ5pvmjw4tXkDTRk8ua {
  margin: 1em 2em;
  width: 400px;
  display: flex;
  border: 1px solid #cacaca;
  border-radius: 4px;
  padding: 1em 1em; }
  ._311oQ5pvmjw4tXkDTRk8ua ._2D4pTF0WIP12xR2cCTz50G {
    max-width: 64px;
    width: 100%;
    margin-right: 10px; }
  ._311oQ5pvmjw4tXkDTRk8ua ._3bqCtfkyHGGWhi81grNE4G {
    width: 80%; }
  ._311oQ5pvmjw4tXkDTRk8ua .SzNBkQkI8O1s8gjXmuRHP {
    width: 20%; }

._2Rkhi2y90nFJWjVOMK1VZE ._2hxOm5Hnqk3ndhc1SrCPKw {
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 1.5rem 2rem 2rem; }

._2Rkhi2y90nFJWjVOMK1VZE ._24SgL1aLa3Oxc4EqyZgJvI {
  display: flex;
  align-items: center; }
  ._2Rkhi2y90nFJWjVOMK1VZE ._24SgL1aLa3Oxc4EqyZgJvI > div:first-child {
    margin-right: 10px; }

._2Rkhi2y90nFJWjVOMK1VZE ._1Zg8bwmKCOK31SMkrBCTxN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem; }
  ._2Rkhi2y90nFJWjVOMK1VZE ._1Zg8bwmKCOK31SMkrBCTxN p {
    font-size: 1.25rem; }

._2Rkhi2y90nFJWjVOMK1VZE ._3W4bgH0sNSWH59h45nLR1y {
  color: #979797; }

._3tvnJScv_ACwqjH11x_10D {
  max-width: 300px;
  width: 100%;
  min-width: 300px; }
