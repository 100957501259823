 { }
  .info-promotion {
    margin: 1em 0; }
    .info-promotion .header {
      display: flex;
      flex-direction: column; }
      .info-promotion .header h2 {
        width: fit-content;
        display: inline-block;
        margin-bottom: -8px;
        background-color: black;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.8;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        padding: 16px 16px;
        margin-right: 8px;
        margin-left: 8px;
        transform: skewX(-15deg); }
    .info-promotion .info-container {
      display: flex;
      flex-wrap: wrap;
      padding: 2em 0px;
      justify-content: space-between; }
      .info-promotion .info-container div.date {
        width: 47.5%; }
        .info-promotion .info-container div.date .head {
          font-family: Europa;
          font-size: 18px;
          font-weight: bold;
          color: #737373; }
        .info-promotion .info-container div.date .group-date {
          display: flex;
          width: 100%;
          justify-content: space-around;
          background-color: white; }
          .info-promotion .info-container div.date .group-date input {
            width: 45%;
            margin: 16px 4px; }
      .info-promotion .info-container div.place {
        width: 47.5%; }
        .info-promotion .info-container div.place .head {
          font-family: Europa;
          font-size: 18px;
          font-weight: bold;
          color: #737373; }
        .info-promotion .info-container div.place .desc {
          font-family: Kanit;
          font-size: 16px;
          text-align: left;
          color: #000000; }

._1-EgN4L2GuW-5_PKPVcTvP {
  max-width: 1400px;
  width: 100%;
  background-color: #ffffff;
  margin: auto;
  padding-bottom: 1rem; }
  ._1-EgN4L2GuW-5_PKPVcTvP .JSo7uc9zbAL5n2_fVXy1q {
    width: 100%;
    min-height: 600px;
    display: grid; }
  ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 420px;
    grid-gap: 10px; }
    ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._8e7IJVt4w3Wlv5kWnn3Eq {
      display: flex;
      flex-direction: column; }
    ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy {
      display: inline-block;
      padding: 8px 16px; }
      ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._3YIBfkurVWFozJl6J2nhZS {
        display: inline-block;
        width: 100%; }
        ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._3YIBfkurVWFozJl6J2nhZS h2 {
          font-size: 18px;
          font-weight: bold;
          color: #282828;
          border-bottom: 3px solid #3779f7;
          width: fit-content; }
      ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG {
        display: inline-block;
        width: 100%;
        padding: 32px 16px; }
        ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG hr {
          margin: 1rem 0; }
        ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG button:disabled {
          opacity: 0.3; }
        ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG .oYU4O6SQI55lvDxzvtvHF button {
          width: 100%;
          margin: 4px 0; }
          ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG .oYU4O6SQI55lvDxzvtvHF button:disabled {
            opacity: 0.3; }
        ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._2p_sLstxO6uvFEwTvidW_I {
          display: flex;
          width: 100%;
          justify-content: center;
          background-color: white; }
          ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._2p_sLstxO6uvFEwTvidW_I button {
            width: 45%;
            margin: 16px 4px; }
            ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._2p_sLstxO6uvFEwTvidW_I button:disabled {
              opacity: 0.3; }
        ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._-0pl0clW7O0x3fuoqFnWr button {
          width: 100%;
          margin: 4px 0; }
          ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._-0pl0clW7O0x3fuoqFnWr button:disabled {
            opacity: 0.3; }
        ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._306Cr7q0eC8HMdTFJ6BNv1 {
          width: 100%;
          padding: 0;
          padding-left: 0; }
          ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._306Cr7q0eC8HMdTFJ6BNv1 ._3sRFIWoDOyoPgB5aCFwmEZ {
            display: inline-block; }
            ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._306Cr7q0eC8HMdTFJ6BNv1 ._3sRFIWoDOyoPgB5aCFwmEZ label {
              padding-right: 16px; }
            ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._306Cr7q0eC8HMdTFJ6BNv1 ._3sRFIWoDOyoPgB5aCFwmEZ span {
              border-radius: 16px;
              padding: 4px 16px; }
              ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._306Cr7q0eC8HMdTFJ6BNv1 ._3sRFIWoDOyoPgB5aCFwmEZ span._3jezxtOWUB0e6kXFbRcMvC {
                color: #979797;
                background-color: #e5e5e5; }
              ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._306Cr7q0eC8HMdTFJ6BNv1 ._3sRFIWoDOyoPgB5aCFwmEZ span._1oQX8uL4_ZEQ6SqcW4gY9n {
                color: #fda70d;
                background-color: #ffedcc; }
              ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._306Cr7q0eC8HMdTFJ6BNv1 ._3sRFIWoDOyoPgB5aCFwmEZ span._3RKzEr0krEWAQIUEB54b13, ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._306Cr7q0eC8HMdTFJ6BNv1 ._3sRFIWoDOyoPgB5aCFwmEZ span._1oNcDAoq7qtXv5yn8fY8jI {
                color: #00cb5f;
                background-color: #c3ffdf; }
        ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._2TiECwXH_xnavQ4j_m13SC label {
          display: block; }
        ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._2TiECwXH_xnavQ4j_m13SC ._3DLkzqo9RO6K3aExZY0Oyf {
          display: flex;
          width: 100%;
          flex-direction: column;
          background-color: white; }
          ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._2TiECwXH_xnavQ4j_m13SC ._3DLkzqo9RO6K3aExZY0Oyf input {
            width: 45%;
            margin: 16px 4px; }
        ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._3SQRiQFqlJbIDGA_mUDSG7 {
          display: flex;
          width: 100%;
          justify-content: center;
          background-color: white;
          flex-wrap: wrap; }
          ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._3SQRiQFqlJbIDGA_mUDSG7 ._2SOJn7uSZjgwbG1iEeIQGe {
            width: 100%; }
            ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._3SQRiQFqlJbIDGA_mUDSG7 ._2SOJn7uSZjgwbG1iEeIQGe button {
              width: 45%;
              margin: 16px 4px; }
              ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._3SQRiQFqlJbIDGA_mUDSG7 ._2SOJn7uSZjgwbG1iEeIQGe button:disabled {
                opacity: 0.3; }
          ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._3SQRiQFqlJbIDGA_mUDSG7 .oLzRU8Idl_mrJH1TwrlIu {
            width: 100%; }
            ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._3SQRiQFqlJbIDGA_mUDSG7 .oLzRU8Idl_mrJH1TwrlIu button {
              width: 95%;
              margin: 16px 4px; }
              ._1-EgN4L2GuW-5_PKPVcTvP ._1h1zIWmtFrIdsLUbt1tOkZ ._2KSiUxUcouHGJl36cWmqyy ._1wGemlkx17t22X_wwQMjYG ._3SQRiQFqlJbIDGA_mUDSG7 .oLzRU8Idl_mrJH1TwrlIu button:disabled {
                opacity: 0.3; }
