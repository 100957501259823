/* ----- Color Variable Spec -----
    p -> Primary
    s -> Secondary
    d -> Dark
    l -> Light
    t -> Status
*/
@media only screen and (max-width: 414px) {
  ._1WNj5LoFI-F4-Ea-tL0okx {
    display: none !important; } }

.kRXMe2i4FP12LuCj3UKOc {
  display: none !important; }
  @media only screen and (max-width: 414px) {
    .kRXMe2i4FP12LuCj3UKOc {
      display: flex !important; } }

._2GMSw0mKyYtPq4WVhYbqs3 p {
  font-size: 1.25rem; }

._-0VsCkpzTwfeahjWLEnAg {
  position: relative;
  background-color: #ffffff;
  border-radius: 3px;
  margin-top: 0.5rem;
  padding: 1rem 1rem 2rem; }

._2g4io0SoGglfMdmxgzhxF4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem; }

button._2Nm1ez529llp_xh8xUZ_OP {
  color: #ffffff;
  background-color: #3779f7; }

button._1SsapybnEKDZ7Vq5Z84XX- {
  color: #fcf8e3;
  background-color: #f9a83d; }

button._2XmjyvPOIje_rj0To8qz8f {
  color: #ffffff;
  background-color: #347633; }

button.SlelsXIAImoKCccYNomdB {
  color: #323232;
  background-color: #e5e5e5; }

button._15cNO4YfnKHFcHDp76Tqdj {
  color: #ffffff;
  background-color: #d30400; }

button._5ZZC3mMHpFARpk3_1qL0L {
  pointer-events: none;
  color: #737373;
  background-color: #e5e5e5; }

._2rtZSgz2owdALBTgF0J3hm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1.5rem 2rem 0rem; }
  ._2rtZSgz2owdALBTgF0J3hm h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem; }
  ._2rtZSgz2owdALBTgF0J3hm p {
    white-space: nowrap; }
  ._2rtZSgz2owdALBTgF0J3hm div {
    border-bottom: 1px solid #323232;
    padding: 1rem 0rem; }
    ._2rtZSgz2owdALBTgF0J3hm div._3y4w6A59PqpASKB9QyCDJl {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px; }
      ._2rtZSgz2owdALBTgF0J3hm div._3y4w6A59PqpASKB9QyCDJl .wRxQJsiLymMoQCihZHpZc {
        grid-column: span 2; }
    ._2rtZSgz2owdALBTgF0J3hm div._2Eh_Rwb4zvv1zD5VjvY_dE {
      display: flex;
      flex-direction: column;
      padding: 1rem; }
      ._2rtZSgz2owdALBTgF0J3hm div._2Eh_Rwb4zvv1zD5VjvY_dE input[type="checkbox"] {
        width: auto; }
    ._2rtZSgz2owdALBTgF0J3hm div.wJmvGZXhv-eexWJu8bFjV {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none; }
  ._2rtZSgz2owdALBTgF0J3hm span {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem; }
  ._2rtZSgz2owdALBTgF0J3hm input {
    width: 100%;
    margin-left: 1rem;
    border-radius: 3px;
    border: 1px solid #4a4a4a; }
    ._2rtZSgz2owdALBTgF0J3hm input[type=file] {
      border: none; }
    ._2rtZSgz2owdALBTgF0J3hm input:disabled {
      background-color: #c6c6c6; }
    ._2rtZSgz2owdALBTgF0J3hm input._3K_dhdZWWUrtVOLVY_8TdP {
      position: relative;
      background-color: transparent;
      margin: 0px 5px; }
      ._2rtZSgz2owdALBTgF0J3hm input._3K_dhdZWWUrtVOLVY_8TdP::-webkit-clear-button {
        display: none; }
      ._2rtZSgz2owdALBTgF0J3hm input._3K_dhdZWWUrtVOLVY_8TdP::-webkit-inner-spin-button {
        display: none; }
      ._2rtZSgz2owdALBTgF0J3hm input._3K_dhdZWWUrtVOLVY_8TdP::-webkit-datetime-edit {
        display: contents;
        font-weight: bold; }
      ._2rtZSgz2owdALBTgF0J3hm input._3K_dhdZWWUrtVOLVY_8TdP::-webkit-calendar-picker-indicator {
        position: absolute;
        height: auto;
        width: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 0;
        cursor: pointer;
        color: transparent;
        background: transparent; }
  ._2rtZSgz2owdALBTgF0J3hm button {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    margin: 0rem 0.5rem; }
  ._2rtZSgz2owdALBTgF0J3hm img.bOZowOQB1_LAANUl1wh0u {
    display: block;
    max-width: 150px;
    max-height: 150px;
    width: 100%; }
