/* ----- Color Variable Spec -----
    p -> Primary
    s -> Secondary
    d -> Dark
    l -> Light
    t -> Status
*/
.xCxCn6P1308LAVcc094qW {
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.5rem 2rem 2rem; }

._3hvh1xLJPss97Q-ZXcLgbS {
  width: 100%;
  max-width: 80px;
  max-height: 50px;
  min-height: 50px;
  background-color: #e5e5e5;
  overflow: hidden;
  border-radius: 4px; }
  ._3hvh1xLJPss97Q-ZXcLgbS img {
    width: 100%;
    min-height: 50px; }

._3_TeHwbP4gzpEFgSgT4F1R {
  margin: 1em 2em;
  width: 400px;
  display: flex;
  border: 1px solid #cacaca;
  border-radius: 4px;
  padding: 1em 1em; }
  ._3_TeHwbP4gzpEFgSgT4F1R .vWsaZ6wvTWT8OrYXjJf9s {
    max-width: 100px;
    width: 100%; }
  ._3_TeHwbP4gzpEFgSgT4F1R .TOn7Bpl-tx4DBUA4TYhuq {
    width: 100%; }

.ZQJEu93Aknf9apGcPvE4Q .mDynsG9SOKBzUzgI5CJJC {
  display: flex;
  justify-content: space-between;
  width: 100%; }
  .ZQJEu93Aknf9apGcPvE4Q .mDynsG9SOKBzUzgI5CJJC h2 {
    max-width: 100%; }

.ZQJEu93Aknf9apGcPvE4Q ._11HMdmLT2BGPv6zaDBj_jD {
  margin-bottom: 20px; }

.ZQJEu93Aknf9apGcPvE4Q ._3iXChGqdbAKki7mkJuZSHV {
  display: inline;
  height: 20px;
  padding: 0px 1em;
  font-size: 0.8em;
  text-align: center; }

.ZQJEu93Aknf9apGcPvE4Q ._39G187fM2q2VR3QqQjuS2U td svg {
  display: block;
  max-height: 50px; }

.ZQJEu93Aknf9apGcPvE4Q ._1GcVeL_N4j711hiXdvIotc {
  border: none;
  background-color: transparent;
  outline: 0;
  font-size: 1.2em; }
  .ZQJEu93Aknf9apGcPvE4Q ._1GcVeL_N4j711hiXdvIotc._31292ed4bJf85Pq3T67ih9 {
    color: #fcb63a; }

.ZQJEu93Aknf9apGcPvE4Q ._14MhfPYcWeAbkm8QXKfYd8 {
  width: 100%; }
  .ZQJEu93Aknf9apGcPvE4Q ._14MhfPYcWeAbkm8QXKfYd8 p {
    margin: 0; }

.ZQJEu93Aknf9apGcPvE4Q .medh0er0QX-DKAwXY6aQQ {
  display: block !important; }

.ZQJEu93Aknf9apGcPvE4Q .dCr_b26ZI2EuYW42oubq9 {
  border-left: 4px solid #3779f7;
  padding: 0 3em; }
  .ZQJEu93Aknf9apGcPvE4Q .dCr_b26ZI2EuYW42oubq9 table thead th {
    font-weight: normal; }
  .ZQJEu93Aknf9apGcPvE4Q .dCr_b26ZI2EuYW42oubq9 table th,
  .ZQJEu93Aknf9apGcPvE4Q .dCr_b26ZI2EuYW42oubq9 table td {
    padding-top: 1.2em !important;
    padding-bottom: 1.2em !important; }
    .ZQJEu93Aknf9apGcPvE4Q .dCr_b26ZI2EuYW42oubq9 table th:first-child,
    .ZQJEu93Aknf9apGcPvE4Q .dCr_b26ZI2EuYW42oubq9 table td:first-child {
      width: 50px;
      max-width: 50px;
      padding-left: 0; }
      .ZQJEu93Aknf9apGcPvE4Q .dCr_b26ZI2EuYW42oubq9 table th:first-child input,
      .ZQJEu93Aknf9apGcPvE4Q .dCr_b26ZI2EuYW42oubq9 table td:first-child input {
        margin: auto;
        max-width: 30px;
        text-align: center; }
        .ZQJEu93Aknf9apGcPvE4Q .dCr_b26ZI2EuYW42oubq9 table th:first-child input::-webkit-outer-spin-button, .ZQJEu93Aknf9apGcPvE4Q .dCr_b26ZI2EuYW42oubq9 table th:first-child input::-webkit-inner-spin-button,
        .ZQJEu93Aknf9apGcPvE4Q .dCr_b26ZI2EuYW42oubq9 table td:first-child input::-webkit-outer-spin-button,
        .ZQJEu93Aknf9apGcPvE4Q .dCr_b26ZI2EuYW42oubq9 table td:first-child input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0; }
  .ZQJEu93Aknf9apGcPvE4Q .dCr_b26ZI2EuYW42oubq9 table td:first-child {
    text-align: center; }

.gyy-18qYru86UzmgApl_i ._1ngbh0v-67-pTybPDi5xvP {
  display: flex;
  width: 500px;
  max-width: 500px; }
  .gyy-18qYru86UzmgApl_i ._1ngbh0v-67-pTybPDi5xvP button {
    width: 30%;
    height: 100px;
    border-radius: 8px;
    margin: 10px 10px;
    border: solid 2px #cacaca;
    background-color: #ffffff; }
    .gyy-18qYru86UzmgApl_i ._1ngbh0v-67-pTybPDi5xvP button._31292ed4bJf85Pq3T67ih9 {
      border: solid 2px #3779f7;
      background-color: #deebff; }
