/* ----- Color Variable Spec -----
    p -> Primary
    s -> Secondary
    d -> Dark
    l -> Light
    t -> Status
*/
.nnCEGEQMA5lTh-M_0a4G1 {
  max-width: 600px;
  width: 100%; }

.w8v97h5rt_j9w5UUysGRC {
  display: flex; }
  .w8v97h5rt_j9w5UUysGRC ._36SUvo_knh1QqQAYx74Eky {
    width: 100%; }
    .w8v97h5rt_j9w5UUysGRC ._36SUvo_knh1QqQAYx74Eky ._1QGaewOU3MpetDAcKm2-UD {
      font-size: 1rem;
      color: #282828; }
    .w8v97h5rt_j9w5UUysGRC ._36SUvo_knh1QqQAYx74Eky ._32UpWKS0zaZJTOFacoWOTq {
      width: 100%;
      padding-top: 15px; }
      .w8v97h5rt_j9w5UUysGRC ._36SUvo_knh1QqQAYx74Eky ._32UpWKS0zaZJTOFacoWOTq ._3zR4bV2MuAy5q215aZq5I1 {
        display: flex;
        justify-content: space-between; }
        .w8v97h5rt_j9w5UUysGRC ._36SUvo_knh1QqQAYx74Eky ._32UpWKS0zaZJTOFacoWOTq ._3zR4bV2MuAy5q215aZq5I1 div:first-child {
          margin-right: 0.5rem; }
