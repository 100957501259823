._2CciYSccBlYjEY60XT0OCh {
  position: relative; }
  ._2CciYSccBlYjEY60XT0OCh ._3Xt3mRFqqlWToiIE4gtReg {
    position: absolute;
    margin-top: 5px;
    z-index: 100; }
    ._2CciYSccBlYjEY60XT0OCh ._3Xt3mRFqqlWToiIE4gtReg._3N7S00EuYh3P4KR-D9srae {
      left: 0; }
    ._2CciYSccBlYjEY60XT0OCh ._3Xt3mRFqqlWToiIE4gtReg.pJhPwWU2YiDD9omC6fXu1 {
      right: 0; }
