/* ----- Color Variable Spec -----
    p -> Primary
    s -> Secondary
    d -> Dark
    l -> Light
    t -> Status
*/
._2OiIOFkAV-taxS7XPEmLsS {
  max-width: 500px;
  width: 100%; }

._5qcfgECQxcaceqBl8JWBb {
  display: flex;
  width: 100%;
  min-height: auto;
  padding: 1.5rem 1rem;
  flex-direction: column; }
