/* ----- Color Variable Spec -----
    p -> Primary
    s -> Secondary
    d -> Dark
    l -> Light
    t -> Status
*/
@media only screen and (max-width: 414px) {
  ._29UG0HkfIAr5mfQCi6uyBg {
    display: none !important; } }

._2cf5qk3a9vfmnDNHSYu3z- {
  display: none !important; }
  @media only screen and (max-width: 414px) {
    ._2cf5qk3a9vfmnDNHSYu3z- {
      display: flex !important; } }

.SZiyHQZiJHmFuTrMzBcgy {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border-radius: 3px;
  padding: 1.5rem 2rem 2rem; }

.hr0NBacx6lO-Hc10oPwp7 {
  margin-bottom: 1rem; }
  .hr0NBacx6lO-Hc10oPwp7 h2 {
    font-weight: normal;
    font-size: 1.25rem;
    border-bottom: 1px solid rgba(115, 115, 115, 0.3); }

._1lFZzug5-XkCJQXlWMgCDW {
  position: relative;
  border-radius: 3px;
  padding: 1rem 0rem; }

._3PhVJkN7gEt46K-2MxvSw1 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }
