/* ----- Color Variable Spec -----
    p -> Primary
    s -> Secondary
    d -> Dark
    l -> Light
    t -> Status
*/
._3mE3lOFnzzw8437a70yj1G {
  max-width: 500px;
  width: 100%; }

._3uuGY_QKWEyll8L5dPFTdV {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px; }

._20kVWXOU35dWeWCOqHsU5t {
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 4px;
  background-color: #1d408b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  margin-right: 10px; }

._278y0tTDEJHT2PvmuNfW1i {
  padding: 5px;
  background-color: transparent;
  border: transparent; }
  ._278y0tTDEJHT2PvmuNfW1i:hover:not(:disabled), ._278y0tTDEJHT2PvmuNfW1i:focus:not(:disabled) {
    background-color: #eef5ff; }
  ._278y0tTDEJHT2PvmuNfW1i:disabled ._1kKmtrIL17V0GVTtntRxAU {
    background-color: #979797; }
  ._278y0tTDEJHT2PvmuNfW1i ._1kKmtrIL17V0GVTtntRxAU {
    background-color: #3779f7;
    color: #ffffff;
    padding: 5px;
    border-radius: 400px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px; }

._56zpEtLE0BVVlk2mC-vYc {
  display: grid;
  grid-template-columns: repeat(10, 40px);
  grid-template-rows: 40px 40px 40px;
  gap: 5px; }
  ._56zpEtLE0BVVlk2mC-vYc.QpM_norKXoATjNY9D2Ub3 {
    display: none; }

.DVk3HDfMBhCA-r7hsitM1 {
  border: 0 transparent;
  border-radius: 200px;
  width: 38px;
  height: 38px;
  color: #979797; }
  .DVk3HDfMBhCA-r7hsitM1.EdvrdRZm_MOx5DtLgE6jC {
    background-color: #1d408b;
    color: #ffffff; }
  .DVk3HDfMBhCA-r7hsitM1._2wbocv-OlWO21ftCCDewl7 {
    border: dashed 1px #979797; }
  .DVk3HDfMBhCA-r7hsitM1.chT7TskE6_LxjzuuakLFQ {
    border: solid 5px #3779f7; }

._3j-anIbz066v35Jkdld-t9 {
  position: relative; }
  ._3j-anIbz066v35Jkdld-t9 ._3oT7rF2eSh-fW6MWPW-IFi {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #ffffff;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    display: flex; }
    ._3j-anIbz066v35Jkdld-t9 ._3oT7rF2eSh-fW6MWPW-IFi.QpM_norKXoATjNY9D2Ub3 {
      display: none; }

.A0nfIaBzPIgAPWPI_dH-1 {
  top: 5px;
  right: 5px;
  position: absolute; }
