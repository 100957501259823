/* ----- Color Variable Spec -----
    p -> Primary
    s -> Secondary
    d -> Dark
    l -> Light
    t -> Status
*/
@media only screen and (max-width: 414px) {
  ._3_rTD6pT0Y5UpTLhbDfdAU {
    display: none !important; } }

._3iLIRl9L_OKydl0Pjc7GJ6 {
  display: none !important; }
  @media only screen and (max-width: 414px) {
    ._3iLIRl9L_OKydl0Pjc7GJ6 {
      display: flex !important; } }

.jIAEHSEwHOkYhn5PiVMzQ {
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center; }
  .jIAEHSEwHOkYhn5PiVMzQ ._2JeXEeHyCEmpuxuCokMTyl {
    max-width: 480px;
    width: 100%; }
    .jIAEHSEwHOkYhn5PiVMzQ ._2JeXEeHyCEmpuxuCokMTyl button[type='submit'] {
      max-width: 100%;
      width: 100%; }
  .jIAEHSEwHOkYhn5PiVMzQ .BfFY3BHHv8wy-KuTnSvBN {
    max-width: 100%; }
