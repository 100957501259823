 { }
  .info-promotion {
    margin: 1em 0; }
    .info-promotion .header {
      display: flex;
      flex-direction: column; }
      .info-promotion .header h2 {
        width: fit-content;
        display: inline-block;
        margin-bottom: -8px;
        background-color: black;
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.8;
        letter-spacing: normal;
        text-align: left;
        color: #ffffff;
        padding: 16px 16px;
        margin-right: 8px;
        margin-left: 8px;
        transform: skewX(-15deg); }
    .info-promotion .info-container {
      display: flex;
      flex-wrap: wrap;
      padding: 2em 0px;
      justify-content: space-between; }
      .info-promotion .info-container div.date {
        width: 47.5%; }
        .info-promotion .info-container div.date .head {
          font-family: Europa;
          font-size: 18px;
          font-weight: bold;
          color: #737373; }
        .info-promotion .info-container div.date .group-date {
          display: flex;
          width: 100%;
          justify-content: space-around;
          background-color: white; }
          .info-promotion .info-container div.date .group-date input {
            width: 45%;
            margin: 16px 4px; }
      .info-promotion .info-container div.place {
        width: 47.5%; }
        .info-promotion .info-container div.place .head {
          font-family: Europa;
          font-size: 18px;
          font-weight: bold;
          color: #737373; }
        .info-promotion .info-container div.place .desc {
          font-family: Kanit;
          font-size: 16px;
          text-align: left;
          color: #000000; }

.NMlCj5vAoTEnSeTvjm6nm {
  max-width: 1400px;
  width: 100%;
  background-color: #ffffff;
  margin: auto; }
  .NMlCj5vAoTEnSeTvjm6nm ._1cXsZrS1a-Xvz52bNQZRLz {
    width: 100%;
    min-height: 600px;
    display: grid; }
  .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 420px;
    grid-gap: 10px; }
    .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV ._3R_Hn5WncS8yX_4bOb8n7- {
      padding-bottom: 1rem; }
    .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba {
      display: inline-block;
      padding: 8px 16px; }
      .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3y4vRWQzx4Haw2T4UsAiS8 {
        display: inline-block;
        width: 100%; }
        .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3y4vRWQzx4Haw2T4UsAiS8 h2 {
          font-size: 18px;
          font-weight: bold;
          color: #282828;
          border-bottom: 3px solid #3779f7;
          width: fit-content; }
      .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY {
        display: inline-block;
        width: 100%;
        padding: 32px 16px; }
        .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY .BblahOrtyw_y6O1VEcMQc button {
          width: 100%;
          margin: 4px 0; }
        .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._1495Npd16-BrdRRSiky7DI {
          display: flex;
          width: 100%;
          justify-content: center;
          background-color: white; }
          .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._1495Npd16-BrdRRSiky7DI button {
            width: 45%;
            margin: 16px 4px; }
        .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._1eEYcwfTWygr5rc6cuSkNb button {
          width: 100%;
          margin: 4px 0; }
        .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._3_Xo2YsjBGpe1XtT464lZz {
          width: 100%;
          padding: 16px 8px;
          padding-left: 0; }
          .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._3_Xo2YsjBGpe1XtT464lZz ._2zfOC6Wucka2Xe5BFhjEvH {
            display: inline-block; }
            .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._3_Xo2YsjBGpe1XtT464lZz ._2zfOC6Wucka2Xe5BFhjEvH label {
              padding-right: 16px; }
            .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._3_Xo2YsjBGpe1XtT464lZz ._2zfOC6Wucka2Xe5BFhjEvH span {
              border-radius: 16px;
              padding: 4px 16px; }
              .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._3_Xo2YsjBGpe1XtT464lZz ._2zfOC6Wucka2Xe5BFhjEvH span.KdiyfuOhWmmIy2WLNYgwX {
                color: #979797;
                background-color: #e5e5e5; }
              .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._3_Xo2YsjBGpe1XtT464lZz ._2zfOC6Wucka2Xe5BFhjEvH span._3g0hkzRXUNH_njSyvAdhCT {
                color: #fda70d;
                background-color: #ffedcc; }
              .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._3_Xo2YsjBGpe1XtT464lZz ._2zfOC6Wucka2Xe5BFhjEvH span._rZEWYZv5efkH06hBGTd- {
                color: #00cb5f;
                background-color: #c3ffdf; }
        .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._3WURru489bjjqUWVeD8J3S label {
          display: block; }
        .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._3WURru489bjjqUWVeD8J3S ._1pRDlQYsKgHcDD5QUFK6Dr {
          display: flex;
          width: 100%;
          justify-content: space-around;
          background-color: white; }
          .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._3WURru489bjjqUWVeD8J3S ._1pRDlQYsKgHcDD5QUFK6Dr input {
            width: 45%;
            margin: 16px 4px; }
        .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._2rUaDr3gvJXBKSU6bFEI-A {
          display: flex;
          width: 100%;
          justify-content: center;
          background-color: white;
          flex-wrap: wrap; }
          .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._2rUaDr3gvJXBKSU6bFEI-A ._2c0lmIJRqeRoFxXihvZXCn {
            width: 100%; }
            .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._2rUaDr3gvJXBKSU6bFEI-A ._2c0lmIJRqeRoFxXihvZXCn button {
              width: 45%;
              margin: 16px 4px; }
          .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._2rUaDr3gvJXBKSU6bFEI-A ._2aLxbAOFaZ5tCjC5EXszoi {
            width: 100%; }
            .NMlCj5vAoTEnSeTvjm6nm ._3ZtTsOkel7n5ZA2eDpmrPV .WWM3Scyt2xkbQoYO30Xba ._3Iz9XGOV4a-bmV7vXDKTkY ._2rUaDr3gvJXBKSU6bFEI-A ._2aLxbAOFaZ5tCjC5EXszoi button {
              width: 95%;
              margin: 16px 4px; }
