/* ----- Color Variable Spec -----
    p -> Primary
    s -> Secondary
    d -> Dark
    l -> Light
    t -> Status
*/
@media only screen and (max-width: 414px) {
  ._1TMC6UBem-pIcySGbjkpP8 {
    display: none !important; } }

._2VBn_JQtWNGP3TzBBiombH {
  display: none !important; }
  @media only screen and (max-width: 414px) {
    ._2VBn_JQtWNGP3TzBBiombH {
      display: flex !important; } }

._3ppjsrYinhLq_BMiFHBAEc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem; }
  ._3ppjsrYinhLq_BMiFHBAEc p {
    font-size: 1.25rem; }

._179nWp5h4PAeTuj9ntSU3i {
  position: relative;
  background-color: #ffffff;
  border-radius: 3px;
  margin-top: 0.5rem;
  padding: 1rem 1rem 2rem; }

button._2-fZRvvdyHClevtdUaKRv- {
  color: #ffffff;
  background-color: #3779f7; }

button._2LM34xy14eELuL--WHUq_c {
  color: #fcf8e3;
  background-color: #f9a83d; }

button._284pYjulkfW0KQfN4XFL-k {
  color: #ffffff;
  background-color: #347633; }

button._2DDGIh_5cLnTwd1VmaQIW1 {
  color: #ffffff;
  background-color: #347633; }

button._3brBeMC754xhgBUQI-9Jbn {
  color: #323232;
  background-color: #e5e5e5; }

button.qMj0ftpMvg6iRSZEG91Wf {
  color: #ffffff;
  background-color: #d30400; }

._5OFb7X595DjaQK31Q4Lmt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1.5rem 2rem 0rem; }
  ._5OFb7X595DjaQK31Q4Lmt h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem; }
  ._5OFb7X595DjaQK31Q4Lmt p {
    white-space: nowrap; }
  ._5OFb7X595DjaQK31Q4Lmt div {
    padding: 1rem 0rem; }
    ._5OFb7X595DjaQK31Q4Lmt div.g78dF_-6TK7A8H1oa1tFr {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px; }
      ._5OFb7X595DjaQK31Q4Lmt div.g78dF_-6TK7A8H1oa1tFr .WIMW4ppEuj_K2VBzIAhqm {
        grid-column: span 2; }
    ._5OFb7X595DjaQK31Q4Lmt div.wRb7x1tqo0oovvolGN1-t {
      display: flex;
      flex-direction: column;
      padding: 1rem; }
    ._5OFb7X595DjaQK31Q4Lmt div._2pKQB4yZNvSIFgJZL2ISV8 {
      display: flex;
      justify-content: center;
      align-items: center;
      border: none; }
    ._5OFb7X595DjaQK31Q4Lmt div.O97NQdECjaa7NDvBd2Q5a span {
      display: grid;
      grid-template-columns: repeat(2, 1fr); }
      ._5OFb7X595DjaQK31Q4Lmt div.O97NQdECjaa7NDvBd2Q5a span input {
        margin-left: 0; }
  ._5OFb7X595DjaQK31Q4Lmt span {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem; }
  ._5OFb7X595DjaQK31Q4Lmt input {
    width: 100%;
    margin-left: 1rem;
    border-radius: 3px;
    border: 1px solid #4a4a4a; }
    ._5OFb7X595DjaQK31Q4Lmt input[type='file'] {
      border: none; }
    ._5OFb7X595DjaQK31Q4Lmt input:disabled {
      background-color: #c6c6c6; }
    ._5OFb7X595DjaQK31Q4Lmt input.Yo10gQG-17Dn-aQ3580Km {
      position: relative;
      background-color: transparent;
      margin: 0px 5px; }
      ._5OFb7X595DjaQK31Q4Lmt input.Yo10gQG-17Dn-aQ3580Km::-webkit-clear-button {
        display: none; }
      ._5OFb7X595DjaQK31Q4Lmt input.Yo10gQG-17Dn-aQ3580Km::-webkit-inner-spin-button {
        display: none; }
      ._5OFb7X595DjaQK31Q4Lmt input.Yo10gQG-17Dn-aQ3580Km::-webkit-datetime-edit {
        display: contents;
        font-weight: bold; }
      ._5OFb7X595DjaQK31Q4Lmt input.Yo10gQG-17Dn-aQ3580Km::-webkit-calendar-picker-indicator {
        position: absolute;
        height: auto;
        width: auto;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 0;
        cursor: pointer;
        color: transparent;
        background: transparent; }
  ._5OFb7X595DjaQK31Q4Lmt button {
    padding: 0.5rem 1rem;
    border-radius: 5px;
    margin: 0rem 0.5rem; }
