/* ----- Color Variable Spec -----
    p -> Primary
    s -> Secondary
    d -> Dark
    l -> Light
    t -> Status
*/
._6RE7mvfXeES5PUeQXsUrB {
  padding-top: 60px;
  vertical-align: middle;
  height: 100vh; }

._2Iah9EpmhazZh11sFfjCtz {
  margin: auto;
  max-width: 540px;
  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(40, 40, 40, 0.2); }
  ._2Iah9EpmhazZh11sFfjCtz h1 {
    color: #1d408b; }
  ._2Iah9EpmhazZh11sFfjCtz p._1JyhfPtgIhyEFahSx5ymqc {
    font-size: 22px; }
  ._2Iah9EpmhazZh11sFfjCtz ._1Q0xeEcdEJ8yjYH2pYl-Yh ._25SBLpuZeQ98dAVEP1Vwg6 {
    padding: 64px 32px 0px 32px; }
  ._2Iah9EpmhazZh11sFfjCtz ._1Q0xeEcdEJ8yjYH2pYl-Yh ._3RCJKOBmvtkzzJUKM_-R0h {
    padding: 44px 32px 32px 32px;
    justify-content: space-between; }
  ._2Iah9EpmhazZh11sFfjCtz ._2lQLIfbspqKHdZ6CO8bPiO {
    display: flex;
    justify-content: space-between; }
    ._2Iah9EpmhazZh11sFfjCtz ._2lQLIfbspqKHdZ6CO8bPiO > div {
      width: 100%; }
      ._2Iah9EpmhazZh11sFfjCtz ._2lQLIfbspqKHdZ6CO8bPiO > div:first-child {
        margin-right: 10px; }
  ._2Iah9EpmhazZh11sFfjCtz .ClNUZng2PhJJCx3p5mf3l {
    margin-bottom: 24px; }
    ._2Iah9EpmhazZh11sFfjCtz .ClNUZng2PhJJCx3p5mf3l._1gtoR9da2QR7LxKJW6CVXO {
      color: #d30400; }
      ._2Iah9EpmhazZh11sFfjCtz .ClNUZng2PhJJCx3p5mf3l._1gtoR9da2QR7LxKJW6CVXO label {
        color: #d30400; }
      ._2Iah9EpmhazZh11sFfjCtz .ClNUZng2PhJJCx3p5mf3l._1gtoR9da2QR7LxKJW6CVXO input {
        border-color: #d30400; }
  ._2Iah9EpmhazZh11sFfjCtz ._1GUgqIC-E9VreQ68uP5s_b {
    color: #d30400; }
  ._2Iah9EpmhazZh11sFfjCtz .Cm6l6mmFOUupbvyMv1v23 {
    text-align: center;
    margin-bottom: 5px; }
    ._2Iah9EpmhazZh11sFfjCtz .Cm6l6mmFOUupbvyMv1v23 .OezC8cE4OkHQ34AbXjZoP {
      font-size: 4.5rem;
      margin-bottom: 20px; }
    ._2Iah9EpmhazZh11sFfjCtz .Cm6l6mmFOUupbvyMv1v23 p {
      margin-bottom: 10px; }
    ._2Iah9EpmhazZh11sFfjCtz .Cm6l6mmFOUupbvyMv1v23 a {
      width: 100%;
      display: block;
      margin: 10px 0px; }
  ._2Iah9EpmhazZh11sFfjCtz ._1PKE5iEcJNSbGXhJre7iND {
    color: #979797; }
  ._2Iah9EpmhazZh11sFfjCtz ._1_3CcxWPJoNMUE9Ju2Spvu {
    width: 100%;
    max-width: 240px; }
  ._2Iah9EpmhazZh11sFfjCtz ._2ltBfBBVV0wtYAQ83kwxlo {
    width: 100%; }
  ._2Iah9EpmhazZh11sFfjCtz ._33jH_l5AiZMmMEa98W0XkK {
    display: flex;
    flex-direction: row-reverse;
    padding: 32px;
    padding-top: 0px; }
