._3Ln2qZbKwGF2G0CLr2HIFZ {
  border: 1px solid #cacaca;
  border-radius: 4px;
  width: 260px;
  background-color: #fff; }
  ._3Ln2qZbKwGF2G0CLr2HIFZ.IPE4tRQJr6dAXkJ06-DD9, ._3Ln2qZbKwGF2G0CLr2HIFZ._2YUddE65nx-pi8JdkCkYSi {
    background-color: #e9ecef;
    opacity: 1; }

.r_ved3CK_797a2YhnaUZI {
  border: transparent;
  background-color: transparent;
  padding: 0.375rem 0.75rem;
  width: 48%; }
