/* ----- Color Variable Spec -----
    p -> Primary
    s -> Secondary
    d -> Dark
    l -> Light
    t -> Status
*/
._2qsToU0OwbX-uhjIlVIW6u {
  position: relative; }
  ._2qsToU0OwbX-uhjIlVIW6u ._2QCWYyl08NvTc0WuDUw5h {
    position: absolute;
    margin-top: 5px;
    z-index: 100; }

._2l2wYZTkc0ajkWPTmhijjp {
  border: 1px solid #cacaca;
  border-radius: 4px;
  width: 100%;
  background-color: #fff;
  position: relative; }

._3ExRwrnJCjmj_ODbJQx4A8 {
  border: transparent;
  padding: 0.375rem 0.75rem;
  margin: 0;
  width: 100%; }
  ._3ExRwrnJCjmj_ODbJQx4A8._2L9oLPuLTm_nXT6xJMxXnf {
    padding-left: 30px; }
  ._3ExRwrnJCjmj_ODbJQx4A8:disabled {
    background-color: #e9ecef; }

._3bQSIMF_-qFWwKVzBilonN {
  position: absolute;
  top: 5px;
  left: 10px; }
